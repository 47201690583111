<template>
  <div class="box">
    <el-form :model="info" label-position="right" :rules="rules" ref="ruleForm" label-width="100px" class="ruleForm" size="small">
      <el-form-item label="名称" prop="title">
        <el-input v-model="info.title" placeholder="请输入名称"></el-input>
      </el-form-item>
      <el-form-item label="位置" prop="type">
        <el-select v-model="info.type" placeholder="请选择">
          <el-option key="1" label="首页" value="1"></el-option>
          <el-option key="2" label="书单" value="2"></el-option>
          <el-option key="3" label="首页弹框" value="3"></el-option>
          <el-option key="4" label="视频头图" value="4"></el-option>
          <el-option key="5" label="系列课轮播图" value="5"></el-option>
          <el-option key="6" label="评论轮播图" value="6"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="图片" prop="img">
        <SelectImgs :selectNum="1" :selectData="[info.img]" :selectFunc="imgChoose" :idx="2"></SelectImgs>
        <span v-if="info.type==1">750*844px</span>
        <span v-if="info.type==2">750*280px</span>
        <span v-if="info.type==3">480*520px</span>
        <span v-if="info.type==4">750*495px</span>
        <span v-if="info.type==5">702*260px</span>
        <span v-if="info.type==6">702*260px</span>
      </el-form-item>
      <el-form-item label="关联类型" prop="data_type">
        <el-select v-model="info.data_type" placeholder="请选择" @change="info.data_id=''" clearable>
          <el-option key="1" label="商品" value="1"></el-option>
          <el-option key="2" label="图书" value="2"></el-option>
          <el-option key="3" label="会员等级" value="3"></el-option>
          <el-option key="4" label="活动" value="4"></el-option>
          <el-option key="5" label="课程" value="5"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择商品" prop="ordering" v-if="info.data_type==1 || info.data_type==2">
        <el-button type="primary" @click="chooseGoods()">选择</el-button> {{info.goods_name}}
      </el-form-item>
      <el-form-item label="会员" v-if="info.data_type==3">
        <el-select v-model="info.data_id" placeholder="请选择">
          <el-option :key="index" :label="item.title" :value="item.id" v-for="(item,index) in gradeList"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="活动" v-if="info.data_type==4">
        <el-select v-model="info.data_id" placeholder="请选择">
          <el-option :key="index" :label="item.title" :value="item.id" v-for="(item,index) in actList"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择课程" prop="ordering" v-if="info.data_type==5">
        <el-button type="primary" @click="chooseCourse()">选择</el-button> {{info.course_name}}
      </el-form-item>
      <el-form-item label="排序" prop="ordering">
        <el-input v-model="info.ordering" placeholder="请输入排序"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        <el-button type="button" @click="reBack()">返回</el-button>
      </el-form-item>
    </el-form>
    <el-dialog
        title="选择商品"
        :visible.sync="showDialog"
        :destroy-on-close="true"
        width="850px">
      <goods-list :goodsType="goodsType" :type="1" :selectFunc="selectGoods"></goods-list>
    </el-dialog>
    <el-dialog
        title="选择课程"
        :visible.sync="showCourseDialog"
        :destroy-on-close="true"
        width="850px">
      <course-list :type="1" :selectFunc="selectCourse"></course-list>
    </el-dialog>
  </div>
</template>
<script>

import SelectImgs from '../../components/SelectImgs'
import GoodsList from "@/components/GoodsList";
import CourseList from "@/components/CourseList";
export default {
  data() {
    return {
      showDialog: false,
      showCourseDialog: false,
      goodsType: 0,
      tableHeader: [],
      tableData: [],
      info: {
        id: 0,
        img: '',
        title: '',
        type: '',
        data_type: '',
        data_id: '',
        goods_name: '',
        ordering: ''
      },
      actList: false,
      gradeList: false,
      source_domain: this.config.SOURCE_DOMAIN,
      rules: {
        title: [
          {required: true, message: '请输入名称', trigger: 'blur'}
        ],
        // data_type: [
        //   {required: true, message: '请选择类型', trigger: 'blur'}
        // ]
      },
    };
  },
  created() {
    if (this.$route.query.id) {
      this.info.id = this.$route.query.id
      this.getInfo()
    }
    this.getActList()
    this.getGradeList()
  },
  mounted() {
  },
  components: {
    SelectImgs,
    GoodsList,
    CourseList
  },
  computed: {},
  methods: {
    selectGoods(goods) {
      this.info.data_id = goods.id
      this.info.goods_name = goods.title
      this.showDialog = false
    },
    selectCourse(course) {
      this.info.data_id = course.id
      this.info.course_name = course.title
      this.showCourseDialog = false
    },
    imgChoose(item,idx) {
      this.info.img = item.pic
    },
    getInfo() {
      var that = this
      this.$api.merchant.bannerDetail({id: that.info.id}, function (res) {
        if (res.errcode == 0) {
          that.info = res.data
          if(that.info.data_id==0) {
            that.info.data_id = ""
          }
          if(that.info.data_type==0) {
            that.info.data_type = ""
          } else {
            that.info.data_type = String(that.info.data_type)
          }
          that.info.type = String(that.info.type)
        } else {
          that.fail(res.errmsg)
        }
      })
    },
    getActList() {
      this.$api.activity.activityIndex({page_size:100},res => {
        if(res.errcode==0) {
          this.actList = res.data.data
        }
      })
    },
    getGradeList() {
      this.$api.member.memberGradeIndex({page_size:100},res => {
        if(res.errcode==0) {
          this.gradeList = res.data.data
        }
      })
    },
    chooseGoods() {
      this.goodsType = 0
      setTimeout(res => {
        this.goodsType = this.info.data_type==1?2:1
        this.showDialog = true
      },100)
    },
    chooseCourse() {
      setTimeout(res => {
        this.showCourseDialog = true
      },100)
    },
    submitForm(formName) {
      var that = this
      this.$refs[formName].validate((valid) => {
        if (valid) {
          var param = this.info
          this.$api.merchant.bannerEdit(param, function (res) {
            if (res.errcode == 0) {
              that.success(res.errmsg)
              that.reBack()
            } else {
              that.fail(res.errmsg)
            }
          })
        } else {
          return false;
        }
      });
    },
  }
};
</script>
<style scoped>
.box {
  background-color: #fff;
  padding: 20px;
  border-radius: 6px;
  min-height: 500px;
}
</style>
